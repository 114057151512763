import React from 'react';

const Loading = () => {
    return (
        <div className="flex flex-col h-screen">
            <div className="mb-auto">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Loading...
                    </h2>
                </div>
            </div>
        </div>
    );
};

export default Loading;
